import React from "react";
import { Link } from "react-scroll";
import { FaWhatsapp } from "react-icons/fa";
const Whatsapplink = () => {
  return (
    <div className="fixed top-[300px] right-[10px] md:right-[30px] lg:right-[40px] bg-whatsapp text-white rounded-xl p-2 hover:bg-darkgreen ">
     <a href="https://wa.me/+2349075765253?text=Hello! I'm texting from clizdesigns.com.ng, I am"> <FaWhatsapp className="text-4xl" /></a>
    </div>
  );
};


export default Whatsapplink;
