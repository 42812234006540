import React from "react";
import Image from "../assets/img/about.webp";

const About = () => {
  return (
    <section id="about" className="section bg-secondary">
      <div className="container mx-auto">
        <div className="flex flex-col xl:flex-row gap-24">
          <img
            className="object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded-2xl"
            src={Image}
            alt=""
          />
          <div className="flex flex-col items-center text-center lg:items-start lg:text-left">
            <div className="flex flex-col">
              <h2 className="text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3  before:content-about relative before:absolute before::opacity-40 before:-top-[2rem]  before:hidden  before:lg:block">
                Elizabeth Chikelu
              </h2>
              <p className="mb-4 text-accent"> Graphics Designer & Web Developer</p>
              <hr className="mb-8 opacity-5"/>
              <p className="mb-8 text-white text-[14px] lg:text-[16px]">
              I am a skilled <b>Graphics designer</b> (Adobe Photoshop, CorelDRAW, and Canva) and <b>Web Developer </b>proficient in Python, Node.js, React, vanilla JavaScript, HTML, and CSS, backed by four years of hands-on experience. My specialization lies in crafting high-end, responsive websites tailored to meet diverse client needs. Furthermore, I offer <b>Branding Services</b> encompassing mugs, shirts, nylons, labels, and more, ensuring a cohesive and impactful brand identity across various mediums.
              </p>
            </div>
         <a href="tel:+2349075765253" className="btn btn-md bg-accent hover:bg-accent-hover transition-all"> Contact me</a>
          </div>{" "}
        </div>
      </div>
    </section>
  );
};

export default About;
