import { useState } from "react";
//import navigation data
import { navigation } from "../data";
//import icons
import { XIcon } from "@heroicons/react/outline";
import { MenuAlt3Icon } from "@heroicons/react/outline";
import Socials from "./Socials";
//import motion
import { motion } from "framer-motion";
//import Link
import { Link } from "react-scroll";
const NavMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  //framer motion variants
  const circleVariants = {
    hidden: {
      scale: 0,
    },
    visible: {
      scale: 180,
      transaction: {
        type: "spring",
        stiffness: 160,
        damping: 60,
      },
    },
  };

  const ulVariants = {
    hidden: {
      opacity: 0,
    },

    visible: {
      opacity: 1,
      transition: {
        delay: 0.1,
      },
    },
  };

  return (
    <nav className="relative">
      {/*menu icon*/}

      <div
        onClick={() => setIsOpen(true)}
        className="cursor-pointer text-white"
      >
        <MenuAlt3Icon className="w-6 h-6" />
        {/* Circle*/}
        <motion.div
          variant={circleVariants}
          initial="hidden"
          animate={isOpen ? "visible" : "hidden"}
          className="="
          w-4
          h-
          rounded-full
          bg-accent
          fixed
          top-0
          right-0
        ></motion.div>{" "}
      </div>

      {/* Circle*/}
      <motion.div
        variants={circleVariants}
        initial="hidden"
        animate={isOpen ? "visible" : "hidden"}
        className="w-4 h-2 rounded-full bg-accent-hover fixed top-0 right-0 "
      ></motion.div>

      {/* Menu */}
      <motion.ul
        variants={ulVariants}
        initial="hidden"
        animate={isOpen ? "visible" : ""}
        className={`${
          isOpen ? "right-0" : "-right-full"
        } fixed top-0 bottom-0 w-full flex flex-col justify-center items-center transition-all duration-300`}
      >
        <div
          onClick={() => setIsOpen(!isOpen)}
          className="cursor-pointer absolute top-8 right-8"
        >
          <XIcon className="w-8 h-8" />
        </div>
        {navigation.map((items, index) => {
          return (
            <li key={index} className="mb-8">
              <Link onClick={() => setIsOpen(!isOpen)}
                to={items.href}
                smooth={true}
                duration={1500}
                offset={-70}
                className="text-xl cursor-pointer capitalize"
              >
                {items.name}
              </Link>
            </li>
          );
        })}
      </motion.ul>
    </nav>
  );
};
export default NavMobile;
