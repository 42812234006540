import React from "react";
import WomanImg from "../assets/img/banner-woman2.webp";
const Hero = () => {
  return (
    <section
      id="home"
      className="lg:h-[-60vh] flex items-center bg-primary lg:bg-cover lg:bg-center lg:bg-no-repeat py-32  lg:py-0 overflow-hidden"
    >
      <div className="container mx-auto h-full">
        <div className="flex items-center h-full pt-8">
          {/* Left Side */}
          <div className="flex-1 flex flex-col items-center lg:items-start">
          {/*  <b className="text-lg text-white mb-[22] "> I am Chikelu Elizabeth</b>*/} 
           <p className="text-1xl lg:text-2xl  text-center text-lg text-accent lg:text-left md:text-left  font-bold">I am Chikelu Elizabeth (Clizdesigns)</p> <br/> 
             <h1 className="text-3xl text-center lg:text-left md:text-left leading-[44px] md:text-3xl md:leading-tight lg:text-5xl lg:leading-[1.2] font-bold md:tracking-[-2px]">
              A Graphics Designer & <br /> Web Developer.
            </h1>
          </div>

          {/* right Side */}
          <div className="hidden lg:flex flex-1 justify-end items-end h-full">
            <img src={WomanImg} alt="" />
          </div>
        </div>{" "}
      </div>
    </section>
  );
};

export default Hero;
