import React from 'react'

// import components
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Brands from './components/Brands';
import Skills from './components/Skills';
import Portfolio from './components/Portfolio';
import Services from './components/Services';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Whatsapplink from './components/Whatsapplink';

const App = () => {
  return (<div>
    <Header/>
    <Hero/>
 {/*   <Brands/>*/} 
    <About/>
    <Skills/>
    <Portfolio/>
    <Services/>
    <Contact/>
    <Footer/>
    <Whatsapplink/>
  </div>);
};

export default App;
