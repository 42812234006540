import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";

import { contact } from "../data";

const Brands = () => {

  //form authentication
  
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

/*const handleChange = (e) => {
 setFormData({ ...formData, [e.target.name]: e.target.value });
 console.log({formData});
};*/

  const [formError, setFormError] = useState('');

/*  const handleSubmit = (e) => {
   e.preventDefault();
   
     if (formData.name.trim() === '' || formData.subject.trim() === '' || formData.email.trim() === '' || formData.message.trim() === '') {
      setFormError('Please fill out all fields.');
    } else {
      // Form is valid, perform authentication or submit data
      console.log('Form submitted:', formData);
      setFormError(' Succesfully Sent');
    }
    console.log(name);
  };
*/
  
    //email sending
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
if (message.length<10){
  setFormError("Please message should not less than 10 Alphabeth")
}
else{
  setFormError("")
  emailjs
  .sendForm("service_jnq9bos", "template_awlz00o", form.current, {
    publicKey: "AjzlynMXoFbdz9Ago",
  })
  .then(
    () => {
      setFormError("SUCCESSFULLY SENT!");
    },
    (error) => {
    //  setFormError("FAILED...", error.text);
    setFormError("NOT SENT...");
    }
  );
}
   
  };

  return (
    <section className=" section bg-primary" id="contact">
      <div className="container mx-auto">
        {/*section title*/}
        <div className="flex flex-col items-center text-center">
          <h2 className="section-title before:content-contact relative before:absolute before:opacity-40 before:-top-7 before:-left-40 before:hidden before:lg:block">
            {" "}
            Contact Me
          </h2>
          <p className="subtitle"> Kindly send me a message</p>
        </div>
        <div className="flex flex-col lg:gap-x-8 lg:gap-x-8 lg:flex-row">
          {/* info */}
          <div className="flex flex-1 flex-col items-start space-y-8 mb-12 lg:mb:0 lg:pt-2">
            {contact.map((item, index) => {
              const { icon, title, subtitle, description, Clicktext } = item;
              return (
                <div className="flex flex-col lg:flex-row gap-x-4" key={index}>
                  <div className="rounded-sm w-14 flex items-start justify-center mt-2 mb-4 lg:mb-0 text-2xl">
                    {icon}
                  </div>
                  <div>
                    <h4 className="font-bold text-xl mb-1">{title}</h4>
                    <p className="mb-1">{subtitle}</p>
                    <p className="text-accent font-normal">
                      <a href={description}>{Clicktext}</a>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          {/*form */}
          <form ref={form} className="space-y-8 w-full max-w-[780px]" onSubmit={sendEmail}>
            <div className="flex gap-8">
              <input className="input" type="text" name="to_name" placeholder="Your Name" value={name} required onChange={(e)=>{setName(e.target.value)}} />
              <input className="input" name="from_name" type="email" placeholder="Your Email" value={email} required onChange={(e)=>{setEmail(e.target.value)}}/>
            </div>
            <input className="input" type="text" name="subject_name" placeholder="Subject" value={subject} required onChange={(e)=>{setSubject(e.target.value)}}/>
            <textarea className="textarea"  name="message" placeholder="Your Mesage" value={message} required onChange={(e)=>{setMessage(e.target.value)}}>
            </textarea>

            <p className="text-accent">{formError}</p>
            <input type="submit" value="Submit" className="btn btn-lg bg-accent hover:bg-accent-hover" />
          </form>
        </div>
      </div>
    </section>
  );
};

export default Brands;


