import React from "react";
//import components
import Projects from "./Projects";
const Porfolio = () => {
  return (
    <section id="portfolio" className="section bg-primary min-h[1400px]">
      <div className="container mx-auto">
        <div className="flex flex-col items-center text-center">
          <h2 className="section-title before:content-porrtfolio relative before:absolute before:opacity-40 before:-top-[2rem] beforre:-left-3/4 before:hidden before:lg:block">
            My latest work
          </h2>
          <p className="subtitle">
          Some of my recent Works...
          </p>
        </div>
        <Projects />
      </div>
    </section>
  );
};

export default Porfolio;
